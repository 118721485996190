import { PERMISSIONS } from '~/constants'

export default defineNuxtRouteMiddleware(async () => {
  const $auth = useAuth()
  const appConfig = useAppConfig()

  const user = await $auth.actions.profile()

  const model = computed(() => {
    let value = ''

    if ($auth.can(PERMISSIONS.cut.view)) {
      value = 'cut'
    } else if ($auth.can(PERMISSIONS.edge.view)) {
      value = 'edge'
    } else if ($auth.can(PERMISSIONS.additive.view)) {
      value = 'additive'
    } else if ($auth.can(PERMISSIONS.pack.view)) {
      value = 'pack'
    }

    return value
  })

  if (user) {
    if ($auth.can(PERMISSIONS.build.view)) {
      return navigateTo({
        name: 'build',
        query: {
          statusId: 1,
          page: 1,
          take: appConfig.DEFAULT_LIMIT
        }
      })
    } else {
      return navigateTo({
        name: 'dashboard',
        query: {
          statusId: 1,
          page: 1,
          sectionId: model.value === 'cut' ? 1 : undefined,
          model: model.value,
          take: appConfig.DEFAULT_LIMIT
        }
      })
    }
  }
})
